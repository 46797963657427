// scroll bar
import "simplebar/src/simplebar.css";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// redux
import { Provider } from "react-redux";
import { store } from "./store";

// main application
import App from "./App";

import { positions, transitions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";



const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
  transition: transitions.SCALE,
}
// ----------------------------------------------------------------------

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate}{...options}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
);
