import { DialogContent, DialogTitle, Dialog, DialogActions } from '@mui/material'
import depositInstance from "src/axios/depositInstance";
import { Grid, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { TableCell, TableBody, TableHead, TableRow, Table, TableContainer, Paper, AppBar, Toolbar } from '@mui/material';
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { loadProfile } from "src/store/actions/authActions";
import { Button } from "@mui/material"
import PopUp from "./PopUp";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import DepositSlip from "./DepositSlip";
import { useNavigate } from "react-router";
import currencyInstance from "src/axios/currencyInstance";
import { CircularProgress } from "@mui/material";
import balancesInstance from "src/axios/balancesInstance";
import { AppWidgetSummary } from "src/sections/@dashboard/app";
import Pagination from 'react-js-pagination';
import "../css/pagination.css"
import EditDepositForm from './EditDepositForm';
import Page from 'src/components/Page';

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const styles = {
  appBar: {
    borderRadius: "0 0 10px 10px",
  },
};
export default function DashboardApp() {
  const [loading, setLoading] = useState(false);
  const [openPopup, setopenPopup] = useState(false);
  const [currencies, setCurrencies] = useState([])
  const [balances, setBalances] = useState([])
  const [deposits, setDeposits] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState()
  const [resultPerPage, setResultPerPage] = useState()
  const [open, setOpen] = useState(false)
  const [toUpdate, setToUpdate] = useState()
  const [slug, setSlug] = useState()
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate()

  const handleRowClick = (slug) => {
    navigate(`/business/deposit-detail/${slug}`)
  }

  const setCurrentPageNo = (e) => {
    setCurrentPage(e)
  }

  const handleBtn = () => {
    setopenPopup(true)
  }
  const handleStop = (event) => {
    event.stopPropagation();
  };

  const fetchCurrencies = async () => {
    try {
      const res = await currencyInstance.get(`/business`)
      const rates = res.data.businessCurrencies
      setCurrencies(rates)
    } catch (error) {
      alert.error(error.response.data.message)
    }
  }

  const fetchDeposits = async () => {
    try {
      const response = await depositInstance.get(`/?page=${currentPage}`);
      const count = response.data.depositCount
      setCount(count)
      const resultPerPage = response.data.resultPerPage
      setResultPerPage(resultPerPage)
      const data = response.data.deposits;
      setDeposits(data)
    } catch (error) {
      alert.error(error.response.data.message)
    }
  }
  const fetchBalances = async () => {
    try {
      const response = await balancesInstance.get(`/remit-user`);
      const data = response.data.userBalance;
      setBalances(data)
    } catch (error) {
      alert.error(error.response.data.message)
    }
  }
  const fetchData = async () => {
    setLoading(true)
    await fetchDeposits()
    await fetchCurrencies()
    await fetchBalances()
    setLoading(false)
  }
  useEffect(() => {
    fetchData()
    dispatch(loadProfile())
  }, [currentPage])

  const submittedData = async (data) => {
    try {
      const response = await depositInstance.post(`/`, data)
      alert.success("Deposit Created")
      setopenPopup(false)
      fetchDeposits()
    } catch (error) {
      alert.error(error.response.data.message)
    }
  }

  const updateDeposit = async (slug, formData) => {
    try {
      await depositInstance.put(`/deposit/${slug}`, formData)
      setOpen(false)
      fetchData()
      alert.success("Edited Sucessfully")

    } catch (error) {
      alert.error(error.response.data.message)

    }
  }

  const deleteDepositHandler = async (slug) => {
    try {
      const response = await depositInstance.put(`/${slug}`)
      alert.success("Deposit Cancelled")
      fetchDeposits()
    } catch (error) {
      alert.error(error.response.data.message)
    }
  };

  const editDeposit = async (slug) => {
    try {
      setSlug(slug)
      const response = await depositInstance.get(`/${slug}`);
      setToUpdate(response.data.deposit)
      setOpen(true)
    } catch (err) {
      alert.error(err.response.data.message)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Page title="KBM Payments">

      {loading ? (
        <LoaderWrapper>
          <CircularProgress size={80} />
        </LoaderWrapper>
      ) : (
        <>

          <Container maxWidth="xl">
            <Grid id='balances-cards' container spacing={3}>
              {(balances.length > 0) &&
                balances?.map((data) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{
                        cursor: "pointer",
                      }
                      }
                    // onClick={() => setEditOpen(true)}
                    >
                      <AppWidgetSummary
                        title={`${data?.currency} balances`}
                        total={data?.balanceValue}
                        icon={(data?.currency === 'GBP') ? "circle-flags:uk" : (data?.currency === 'USD') ? "circle-flags:us" : (data?.currency === 'EUR') ? "circle-flags:eu" : (data?.currency === 'SDG') ? "emojione:flag-for-sudan" : 'grommet-icons:currency'}
                        color='primary'
                      />
                    </Grid>
                  )
                })
              }
            </Grid>
            <br />
            <Button onClick={handleBtn} style={{ float: "right", marginBottom: "1rem" }} variant="contained">Deposit</Button>
            <PopUp title="Deposit Slip" openPopup={openPopup} setopenPopup={setopenPopup}>
              <DepositSlip balances={balances} submittedData={submittedData} currencies={currencies} />
            </PopUp>
            <AppBar style={styles.appBar} position="static">
              <Toolbar variant="dense">
                <Typography variant="h6" color="inherit" component="div">
                  Deposits List
                </Typography>
              </Toolbar>
            </AppBar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="right">Created Date</TableCell>
                    <TableCell align="center">Action</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {deposits.map((row) => (
                    <TableRow
                      hover
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => { handleRowClick(row.slug) }}
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">{row.amount}{'(' + row.currency + ')'}</TableCell>
                      <TableCell style={{ color: (row.status === 'Approved') ? 'green' : (row.status === 'Cancelled') ? 'red' : (row.status === 'Paid') ? 'blue' : 'black' }} align="center">{row.status}</TableCell>
                      <TableCell align="right">{row.createdAt.substr(0, 10)}</TableCell>
                      <TableCell onClick={handleStop} align="center">
                        <Button disabled={(row.status === 'Pending') ? false : true} onClick={() => editDeposit(row.slug)}><EditIcon /></Button>
                        <Button disabled={(row.status === 'Cancelled' || row.status !== 'Pending') ? true : false} color="warning" onClick={() => deleteDepositHandler(row.slug)}><CancelIcon /></Button>

                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {resultPerPage < count &&
              <div className="paginationBox">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={resultPerPage}
                  totalItemsCount={count}
                  onChange={setCurrentPageNo}
                  nextPageText="Next"
                  prevPageText="Prev"
                  firstPageText="1st"
                  lastPageText="Last"
                  itemClass='page-item'
                  linkClass='page-link'
                  activeClass='pageItemActive'
                  activeLinkClass='pageLinkActive'
                />
              </div>
            }
          </Container>
        </>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >Edit Deposit
          <DialogContent>
            <EditDepositForm updateDeposit={updateDeposit} toUpdate={toUpdate} currencies={currencies} slug={slug} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </DialogTitle>
      </Dialog>
    </Page>
  );
}
