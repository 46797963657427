import React, { useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import PreviewImage from 'src/components/fileReader';
import "../css/check.css"
import * as Yup from "yup";
import {
    FormControl,
    TextField,
    MenuItem,
    Select,
    Button,
    InputLabel,
    Grid,
    Typography,
    Input,
} from '@mui/material';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles"
import { useFormik, Form, FormikProvider } from "formik";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    input: {
        display: 'none',
    },
    previewContainer: {
        marginTop: theme.spacing(2),
    },
    previewImage: {
        maxWidth: '20%',
    }
}));

// const currencies = [
//     {
//         value: '$',
//         label: '$(USD)',
//     },
//     {
//         value: '€',
//         label: '€(EUR)',
//     },
//     {
//         value: '£',
//         label: '£(GBP)',
//     },
// ];

const DepositSlip = (props) => {
    const { submittedData, currencies, balances } = props
    const classes = useStyles();

    const depositSchema = Yup.object().shape({
        currency: Yup.string().required("Currency is required"),
        amount: Yup.number("Amount Must be a Number").required("Amount is required"),
        description: Yup.string().required("Description is required"),
        image: Yup.mixed().required("Image Proof Required*"),
        action: Yup.string()
    })

    const formik = useFormik({
        initialValues: {
            currency: "",
            amount: '',
            description: "",
            image: "",
            action: 'businessDeposit'
        },
        validationSchema: depositSchema,
        onSubmit: async (values, { setErrors }) => {
            const formData = new FormData();
            formData.append("currency", values.currency)
            formData.append("amount", values.amount)
            formData.append("description", values.description)
            formData.append("action", values.action)
            formData.append("image", values.image)
            try {
                await submittedData(formData);
            } catch (err) {
                setErrors({ error: err.response.data.message });
            }
        },
    })

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, handleChange } =
        formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="currency-label" {...getFieldProps("currency")}
                                    error={Boolean(touched.currency && errors.currency)}
                                    helperText={touched.currency && errors.currency}>Currency</InputLabel>
                                <Select
                                    labelId="currency-label"
                                    id="currency"
                                    required
                                    {...getFieldProps("currency")}
                                    error={Boolean(touched.currency && errors.currency)}
                                    helperText={touched.currency && errors.currency}
                                >
                                    {balances.map((option) => (
                                        <MenuItem key={option.currency} value={option.currency}>
                                            {"(" + option.currency + ")"}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // className={classes.textField}
                                label="Amount"
                                type="number"
                                required
                                fullWidth
                                {...getFieldProps("amount")}
                                error={Boolean(touched.amount && errors.amount)}
                                helperText={touched.amount && errors.amount}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="description"
                                label="Description"
                                fullWidth
                                multiline
                                rows={3}
                                value={values.description}
                                onChange={handleChange}
                                {...getFieldProps("description")}
                                error={Boolean(touched.description && errors.description)}
                                helperText={touched.description && errors.description}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                type="file"
                                accept="image/*,application/pdf"
                                className={classes.input}
                                id="image-upload"
                                onChange={(e) => {
                                    setFieldValue("image", e.target.files[0])
                                }}
                            />
                            <label htmlFor="image-upload">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    startIcon={<CloudUploadIcon style={{ color: "white" }} />}
                                >
                                    <Typography color="white">
                                        Upload Slip
                                    </Typography>
                                </Button>
                                {errors.image ? <div style={{ color: 'red', fontSize: 'small' }}>{errors.image}</div> : null}
                            </label>

                            {values.image &&
                                <>
                                    {/* <Button style={{ marginLeft: "50rem" }}><CancelIcon /></Button> */}
                                    <PreviewImage file={values.image} />
                                </>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Box textAlign='center'>
                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    color="primary"
                                >
                                    <Typography color="white">
                                        Submit
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </>
    )
}

export default DepositSlip