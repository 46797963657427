import { DialogContent, DialogTitle, Dialog } from '@mui/material'
import React, { useState } from 'react'
import { makeStyles, useTheme } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, Typography } from '@mui/material';
import { AppBar, Toolbar, useMediaQuery, Grid } from '@mui/material';



const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        paddingRight: '0px',
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            padding: '8px 16px',
            marginLeft: '60px'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '14px',
            padding: '10px 20px',
            marginLeft: '550px'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '16px',
            padding: '12px 24px',
            marginLeft: '580px'
        },
    },
}))

export default function PopUp(props) {
    const classes = useStyles();
    const { openPopup, children, title, setopenPopup } = props;

    return (
        <>
            <Dialog open={openPopup} maxWidth="md">
                <AppBar position="static" >
                    <Toolbar variant="dense">
                        <DialogTitle>
                            <div style={{ display: "flex" }}>
                                <Typography variant='h4' style={{ flexGrow: 1 }}>
                                    {title}
                                </Typography>
                                <Button className={classes.button} sx={{ color: "#FFFFFF" }} onClick={() => setopenPopup(false)}>
                                    <CancelIcon />
                                </Button>
                            </div>
                        </DialogTitle>
                    </Toolbar>
                </AppBar>

                <DialogContent dividers>
                    {children}
                </DialogContent>

            </Dialog >
        </>
    )
}

