import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '20px',
    margin: '20px 0',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    textAlign:'center'
}
}));

const ContactUs = () => {
  const classes = useStyles()
  return (
    <div className={classes.card}>If you have any questions, Contact us on: support@kbmbusiness.co.uk</div>
  )
}

export default ContactUs