import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from "@mui/material";
import { DialogContent, DialogTitle, Dialog, DialogActions } from '@mui/material'
import { useNavigate } from "react-router-dom";
import { TableCell, TableBody, TableHead, TableRow, Table, TableContainer, Paper, AppBar, Toolbar, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PopUp from "./PopUp";
import InvoiceForm from "./InvoiceForm"
import { useAlert } from "react-alert";
import balancesInstance from "src/axios/balancesInstance";
import invoiceInstance from "src/axios/invoiceInstance";
import { CircularProgress } from "@mui/material";
import Pagination from 'react-js-pagination';
import "../css/pagination.css"
import EditInvoiceForm from "./EditInvoiceForm";
const LoaderWrapper = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
}));

const styles = {
    appBar: {
        borderRadius: "0 0 10px 10px",
    },
};

const InvoiceList = () => {
    const [loading, setLoading] = useState(false);
    const [openPopup, setopenPopup] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [count, setCount] = useState()
    const [resultPerPage, setResultPerPage] = useState()
    const [toUpdate, setToUpdate] = useState()
    const [slug, setSlug] = useState()
    const [open, setOpen] = useState(false)
    const [balances,setBalances]=useState([])
    const alert = useAlert();
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false)
    }

    const [invoices, setInvoices] = useState([])

    const handleRowClick = (slug) => {
        navigate(`/business/invoice-detail/${slug}`)
    }
    const handleStop = (event) => {
        event.stopPropagation();
    };
    const setCurrentPageNo = (e) => {
        setCurrentPage(e)
    }

    const fetchInvoices = async () => {
        try {
            setLoading(true)
            const response = await invoiceInstance.get(`/?page=${currentPage}`);
            const count = response.data.invoiceCount
            setCount(count)
            const resultPerPage = response.data.resultPerPage
            setResultPerPage(resultPerPage)
            const data = response.data.invoices;
            setInvoices(data)
            setLoading(false)
        } catch (error) {
            alert.error(error.response.data.message)
        }
    }
    const fetchBalances = async () => {
        try {
          setLoading(true)
          const response = await balancesInstance.get(`/remit-user`);
          const data = response.data.userBalance;
          setBalances(data)
          setLoading(false)
        } catch (error) {
          alert.error(error.response.data.message)
        }
      }

    useEffect(() => {
        fetchBalances()
        fetchInvoices()
    }, [currentPage])

    const handleBtn = () => {
        setopenPopup(true)
    }

    const submittedData = async (data) => {
        try {
            const response = await invoiceInstance.post(`/`, data)
            alert.success("Invoice Created")
            setopenPopup(false)
            fetchInvoices()
        } catch (error) {
            alert.error(error.response.data.message)
        }
    }

    const deleteInvoiceHandler = async (slug) => {
        try {
            const response = await invoiceInstance.put(`/${slug}`)
            alert.success("Invoice Cancelled")
            fetchInvoices()
        } catch (error) {
            alert.error(error.response.data.message)
        }
    };

    const editInvoiceHandler = async (slug) => {
        try {
            setSlug(slug)
            const response = await invoiceInstance.get(`/${slug}`);
            setToUpdate(response.data.invoice)
            setOpen(true)
        } catch (err) {
            alert.error(err.response.data.message)
        }
    }

    const updateInvoice = async (slug, formData) => {
        try {
            await invoiceInstance.put(`/invoice/${slug}`, formData)
            setOpen(false)
            fetchInvoices()
            alert.success("Edited Sucessfully")

        } catch (error) {
            alert.error(error.response.data.message)

        }
    }

    return (
        <>
            {loading ? (
                <LoaderWrapper>
                    <CircularProgress size={80} />
                </LoaderWrapper>
            ) : (
                <>
                    <Button onClick={(e) => handleBtn()} style={{ float: "right", marginBottom: "1rem" }} variant="contained">Add Invoice</Button>
                    <PopUp title="Invoice Form" openPopup={openPopup} setopenPopup={setopenPopup}>
                        <InvoiceForm balances={balances} submittedData={submittedData} />
                    </PopUp>
                    <AppBar style={styles.appBar} position="static">
                        <Toolbar variant="dense">
                            <Typography variant="h6" color="inherit" component="div">
                                Invoices List
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Company Name</TableCell>
                                    <TableCell align="center">Amount</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Created Date</TableCell>
                                    <TableCell align="center">Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    invoices
                                        .map((invoice) => (
                                            <TableRow
                                                hover
                                                key={invoice.name}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => { handleRowClick(invoice.slug) }}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="left">{invoice.companyName}</TableCell>
                                                <TableCell align="center">{invoice.amount}{'(' + invoice.currency + ')'}</TableCell>
                                                <TableCell style={{ color: (invoice.status === 'Approved') ? 'green' : (invoice.status === 'Cancelled') ? 'red' : (invoice.status === 'Paid') ? 'blue' : 'black' }} align="right">{invoice.status}</TableCell>
                                                <TableCell align="right">{invoice.createdAt.substr(0, 10)}</TableCell>
                                                <TableCell onClick={handleStop} align="center">
                                                    <Button disabled={(invoice.status === 'Pending') ? false : true} onClick={() => editInvoiceHandler(invoice.slug)} ><EditIcon /></Button>
                                                    <Button disabled={(invoice.status === 'Cancelled' || invoice.status !== 'Pending') ? true : false} color="warning" onClick={() => deleteInvoiceHandler(invoice.slug)}><CancelIcon /></Button>

                                                </TableCell>

                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {resultPerPage < count &&
                        <div className="paginationBox">
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={resultPerPage}
                                totalItemsCount={count}
                                onChange={setCurrentPageNo}
                                nextPageText="Next"
                                prevPageText="Prev"
                                firstPageText="1st"
                                lastPageText="Last"
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='pageItemActive'
                                activeLinkClass='pageLinkActive'
                            />
                        </div>
                    }
                </>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle >Edit Invoice
                    <DialogContent>
                        <EditInvoiceForm updateInvoice={updateInvoice} toUpdate={toUpdate} slug={slug} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </DialogTitle>
            </Dialog>
        </>
    )
}

export default InvoiceList