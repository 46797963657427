import React, { useState, useEffect } from 'react'
import invoiceInstance from 'src/axios/invoiceInstance';
import { CircularProgress, Box } from "@mui/material";
import { useParams } from 'react-router-dom'
import "../css/invoiceImage.css"
import { styled } from "@mui/material/styles";
import { useAlert } from 'react-alert';
import { Typography, Grid, Paper } from '@mui/material'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import CloseIcon from '@mui/icons-material/Close';
import Modal from 'react-modal';
import "../css/detailView.css"


const LoaderWrapper = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
}));


const InvoiceDetails = () => {
    const [loading, setLoading] = useState(false);
    const alert = useAlert()
    const [invoice, setInvoice] = useState({})
    const [invoiceOpen, setInvoiceOpen] = useState(false);
    const { slug } = useParams()

    const openInvoiceModal = () => {
        setInvoiceOpen(true);
    };

    const closeInvoiceModal = () => {
        setInvoiceOpen(false);
    };

    const fetchInvoices = async () => {
        try {
            setLoading(true)
            const response = await invoiceInstance.get(`/${slug}`);
            const inv = response?.data?.invoice;
            setInvoice(inv)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    const docs = [
        { uri: invoice?.imageAdmin },
    ];

    const docus = [
        { uri: invoice?.imageInvoice }
    ]

    useEffect(() => {
        fetchInvoices()
    }, [])
    return (
        <>
            {loading ? (
                <LoaderWrapper>
                    <CircularProgress size={80} />
                </LoaderWrapper>
            ) : (
                <Paper elevation={3} sx={{ padding: '2rem' }}>
                    <Typography variant="h4" align="center" gutterBottom marginBottom={3}>
                        Invoice Slip
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid className='border' item xs={12} md={6}>
                            <Typography variant="body2" gutterBottom>
                                <strong>Company Name: &emsp;</strong>{invoice.companyName}
                            </Typography>
                            <br />
                            <Typography variant="body2" gutterBottom>
                                <strong>Description:  &emsp;</strong>{invoice.description}
                            </Typography>
                            <br />
                            <Typography variant="body2" gutterBottom>
                                <strong>Amount:  &emsp;</strong>{invoice.amount}{invoice.currency}
                            </Typography>
                            <br />
                            <Typography style={{ color: (invoice.status === 'Approved') ? 'green' : (invoice.status === 'Cancelled') ? 'red' : (invoice.status === 'Paid') ? 'blue' : 'black' }} variant="body2" gutterBottom>
                                <strong style={{ color: "black" }}>Status: &emsp;</strong>{invoice.status}
                            </Typography>
                            <br />
                            <Typography variant="body2" gutterBottom>
                                <strong>Remarks:  &emsp;</strong>{(!invoice.remarks)?"No Remarks":invoice.remarks}
                            </Typography>
                            <br/>
                            {(invoice.paymentMethod === 'IBAN') ?
                                <>
                                    <Typography variant="body2" ><strong>IBAN:  &emsp;</strong>{invoice.iban}</Typography>

                                </>
                                :
                                <>
                                    <Typography variant="body2" ><strong>Account No:  &emsp;</strong>{invoice.accountNo}</Typography>
                                    <br />
                                    <Typography variant="body2" ><strong>Swift BIC:  &emsp;</strong>{invoice.swiftBIC}</Typography>
                                </>}
                            <br />
                            <Typography variant="body2" gutterBottom>
                                <strong>Created Date:  &emsp;</strong>{invoice.createdAt?.substr(0, 10)}
                            </Typography>
                            <br />
                        </Grid>
                        <Grid className='border' item xs={12} md={6}>
                            <Typography variant="body2" gutterBottom>
                                <strong>User Slip: </strong>
                            </Typography>
                            {(!invoice?.imageInvoice) ?
                                <h3 style={{ color: "red" }}>Slip Not Uploaded Yet</h3> :
                                (invoice?.imageInvoice.endsWith('.pdf')) ?
                                    <>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <DocViewer style={{ width: "27rem", height: "27rem" }} documents={docus} pluginRenderers={DocViewerRenderers} config={{
                                                header: {
                                                    disableHeader: true,
                                                    disableFileName: true,
                                                    retainURLParams: false
                                                }
                                            }} />
                                        </Box>
                                    </> :
                                    <>
                                        <img style={{ height: "100%", width: "100%" }} onClick={openInvoiceModal} src={invoice.imageInvoice} alt='image' />
                                        <Modal
                                            isOpen={invoiceOpen}
                                            onRequestClose={closeInvoiceModal}
                                            contentLabel="Enlarged Image"
                                            style={customModalStyles}
                                        >
                                            <button onClick={closeInvoiceModal}><CloseIcon /></button>
                                            <img src={invoice?.imageInvoice} alt="Enlarged Image" />
                                        </Modal>
                                    </>

                            }
                        </Grid>
                        <Grid className='border' item xs={12} md={12}>
                            <Typography variant="body2" gutterBottom>
                                <strong>Admin Slip: </strong>
                            </Typography>
                            {(!invoice?.imageAdmin) ?
                                <h3 style={{ color: "red" }}>Slip Not Uploaded Yet</h3> :
                                (invoice?.imageAdmin.endsWith('.pdf')) ?
                                    <>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <DocViewer style={{ width: "27rem", height: "27rem" }} documents={docs} pluginRenderers={DocViewerRenderers} config={{
                                                header: {
                                                    disableHeader: true,
                                                    disableFileName: true,
                                                    retainURLParams: false
                                                }
                                            }} />
                                        </Box>
                                    </> :
                                    <>
                                        <img style={{ height: "95%", width: "100%" }} onClick={openInvoiceModal} src={invoice.imageAdmin} alt='image' />
                                        <Modal
                                            isOpen={invoiceOpen}
                                            onRequestClose={closeInvoiceModal}
                                            contentLabel="Enlarged Image"
                                            style={customModalStyles}
                                        >
                                            <button onClick={closeInvoiceModal}><CloseIcon /></button>
                                            <img src={invoice?.imageAdmin} alt="Enlarged Image" />
                                        </Modal>
                                    </>

                            }
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </>
    )
}


const customModalStyles = {
    content: {
        position: 'absolute',
        top: '50%',
        left: '60%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '80%',
        backgroundColor: 'white',
        border: 'none',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        padding: '20px',
    },
};

export default InvoiceDetails