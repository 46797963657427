import React, { useState, useEffect } from 'react'
import currencyInstance from 'src/axios/currencyInstance';
import { useFormik, Form, FormikProvider } from "formik";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from '@mui/lab';
import * as Yup from "yup";
import {
    FormControl,
    TextField,
    MenuItem,
    Select,
    Button,
    InputLabel,
    Typography,
} from '@mui/material';
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "../../src/css/invoiceImage.css"
import { makeStyles } from "@mui/styles"
import PreviewImage from 'src/components/fileReader';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    input: {
        display: 'none',
    },
    previewContainer: {
        marginTop: theme.spacing(2),
    },
    previewImage: {
        maxWidth: '100%',
    }
}));


const EditInvoiceForm = (props) => {
    const { toUpdate, slug, updateInvoice } = props
    const classes = useStyles();
    const [currencies, setCurrencies] = useState([])

    const fetchCurrencies = async () => {
        try {
            const res = await currencyInstance.get(`/business`)
            const rates = res.data.businessCurrencies
            setCurrencies(rates)
        } catch (error) {
            alert.error(error.response.data.message)
        }
    }
    useEffect(() => {
        fetchCurrencies()
    }, [])

    const InvoiceSchema = Yup.object().shape({
        companyName: Yup.string(),
        ref: Yup.string(),
        description: Yup.string(),
        currency: Yup.string(),
        amount: Yup.number().positive(),
        paymentMethod: Yup.string(),
        iban: Yup.string(),
        accountNo: Yup.string().nullable(),
        swiftBIC: Yup.string(),
        imageInvoice: Yup.mixed(),
        action: Yup.string()
    })


    const formik = useFormik({
        initialValues: {
            companyName: `${toUpdate.companyName}`,
            ref: `${toUpdate.ref}`,
            description: `${toUpdate.description}`,
            currency: `${toUpdate.currency}`,
            amount: `${toUpdate.amount}`,
            iban: `${toUpdate.iban}`,
            accountNo: toUpdate.accountNo === null ? '' : toUpdate.accountNo,
            paymentMethod: `${toUpdate.paymentMethod}`,
            swiftBIC: `${toUpdate.swiftBIC}`,
            imageInvoice: `${toUpdate.imageInvoice}`,
            action: "businessInvoice"
        },
        validationSchema: InvoiceSchema,
        onSubmit: async (values, { setErrors }) => {
            const formData = new FormData();
            formData.append("companyName", values.companyName)
            formData.append("ref", values.ref)
            formData.append("description", values.description)
            formData.append("currency", values.currency)
            formData.append("amount", values.amount)
            formData.append("paymentMethod", values.paymentMethod)
            formData.append("iban", values.iban)
            formData.append("accountNo", values.accountNo)
            formData.append("swiftBIC", values.swiftBIC)
            formData.append("action", values.action)
            formData.append("imageInvoice", values.imageInvoice)
            try {
                await updateInvoice(slug, formData)
            } catch (err) {
                setErrors({ error: err.response.data.message });
            }
        },
    })


    const { errors, touched, number, handleSubmit, handleChange, values, getFieldProps, setFieldValue, isSubmitting } = formik;


    return (
        <>
            <React.Fragment>
                <Paper elevation={3} sx={{ marginRight: "1%", marginLeft: "1%" }}>
                    <Box sx={{ padding: 5 }}>
                        <FormikProvider value={formik}>
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <Typography fontSize={20} fontWeight={700}>
                                            Basic Information
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="companyName"
                                            label="Company Name"
                                            fullWidth
                                            value={values.companyName}
                                            onChange={handleChange}
                                            {...getFieldProps("companyName")}
                                            error={Boolean(touched.companyName && errors.companyName)}
                                            helperText={touched.companyName && errors.companyName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="ref"
                                            label="Reference"
                                            fullWidth
                                            value={values.ref}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="description"
                                            label="Description"
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={values.description}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Typography fontSize={20} fontWeight={700}>
                                            Account Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="currency-label"  {...getFieldProps("currency")}
                                                error={Boolean(touched.currency && errors.currency)}
                                                helperText={touched.currency && errors.currency}>Select Currency</InputLabel>
                                            <Select
                                                labelId="currency-label"
                                                id="currency"
                                                value={values.currency}
                                                onChange={handleChange}
                                                {...getFieldProps("currency")}
                                                error={Boolean(touched.currency && errors.currency)}
                                                helperText={touched.currency && errors.currency}

                                            >
                                                {currencies.map((option) => (
                                                    <MenuItem key={option.name} value={option.name}>
                                                        {"(" + option.name + ")"}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="amount"
                                            label="Enter Amount"
                                            fullWidth
                                            type="number"
                                            value={values.amount}
                                            onChange={handleChange}
                                            {...getFieldProps("amount")}
                                            error={Boolean(touched.amount && errors.amount)}
                                            helperText={touched.amount && errors.amount}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="payment-method-label"
                                                {...getFieldProps("paymentMethod")}
                                                error={Boolean(touched.paymentMethod && errors.paymentMethod)}
                                                helperText={touched.paymentMethod && errors.paymentMethod}
                                            >Select Payment Method</InputLabel>
                                            <Select
                                                labelId="payment-method-label"
                                                id="paymentMethod"
                                                value={values.paymentMethod}
                                                onChange={handleChange}
                                                {...getFieldProps("paymentMethod")}
                                                error={Boolean(touched.paymentMethod && errors.paymentMethod)}
                                                helperText={touched.paymentMethod && errors.paymentMethod}
                                            >
                                                <MenuItem value="IBAN">IBAN</MenuItem>
                                                <MenuItem value="AccountNumber">Account Number</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {values.paymentMethod === 'IBAN' && (

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="iban"
                                                label="Enter IBAN"
                                                fullWidth
                                                value={values.iban}
                                                onChange={handleChange}
                                                {...getFieldProps("iban")}
                                                error={Boolean(touched.iban && errors.iban)}
                                                helperText={touched.iban && errors.iban}
                                            />
                                        </Grid>
                                    )}
                                    {values.paymentMethod === 'AccountNumber' && (
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="accountNo"
                                                    label="Enter Account Number"
                                                    fullWidth
                                                    type="number"
                                                    value={values.accountNo}
                                                    onChange={handleChange}
                                                    {...getFieldProps("accountNo")}
                                                    error={Boolean(touched.accountNo && errors.accountNo)}
                                                    helperText={touched.accountNo && errors.accountNo}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="swiftBIC"
                                                    label="Enter Swift BIC"
                                                    fullWidth
                                                    value={values.swiftBIC}
                                                    onChange={handleChange}
                                                    {...getFieldProps("swiftBIC")}
                                                    error={Boolean(touched.swiftBIC && errors.swiftBIC)}
                                                    helperText={touched.swiftBIC && errors.swiftBIC}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <input
                                            type="file"
                                            accept="image/*,application/pdf"
                                            className={classes.input}
                                            id="image-upload"
                                            onChange={(e) => {
                                                setFieldValue("imageInvoice", e.target.files[0])
                                            }}
                                        />
                                        <label htmlFor="image-upload">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                Upload Slip
                                            </Button>
                                            {errors.imageInvoice ? <div style={{ color: 'red', fontSize: 'small' }}>{errors.imageInvoice}</div> : null}
                                        </label>

                                        {values.imageInvoice &&
                                            <>
                                                {/* <Button style={{ marginLeft: "43rem" }}><CancelIcon /></Button> */}
                                                <PreviewImage file={values.imageInvoice} />
                                            </>
                                        }

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box textAlign='center'>
                                            <LoadingButton
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitting}
                                                color="primary"
                                            >
                                                <Typography color="white">
                                                    Update
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </Box>
                </Paper>
            </React.Fragment >
        </>
    )
}

export default EditInvoiceForm