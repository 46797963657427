import React from 'react'
import "../css/HowItWorks.css"

const HowItWorks = () => {
  return (
    <div className='top'>
    <div className="header">
        <h1>How it works</h1>
    </div>
    <div className="container">
        <div className="sub-heading">
           Your Dashboard
        </div>
        <div className='card'>
        <p>• In your dashboard you can see your updated cash balances and navigate to Deposits and Invoices Payments tools.</p>
        </div>
        <div className="arrow1"></div>
        <div className="sub-heading">
            Deposits
        </div>
        <div className='card'>
        <p>• As soon as you have deposited a cash amount via our approved channels, go to Deposits page and click on Deposit Button. A pop-up page will appear in which you are asked to provide the currency, the deposited amount and a description for your potential use and other important details. It is also important here to provide a proof of your deposit through the slip button, which is for you to upload the deposit slip.</p>
        <p>• Once these were submitted the payment will go through our verification process, please allow some time for your payment to be reflected in your account balance.</p>
        </div>
        <div className="arrow1"></div>
        <div className="sub-heading">
            Invoices Payments
        </div>
        <div className='card'>
        <p>• Here is where you instruct us with Payment to your vendors. Click on Pay Invoice, a pop-up window will appear where you can fill in your payee information as well as how you would like them to get paid by choosing the most appropriate option for payment method. </p>
        <p>• Once you have submitted these, they will go through verification and processing steps, and these will be shown to you on the Invoices Payments page. </p>
        <p>• The Invoices Payments page shows you all payments request made. Here you can see all their details, status, and actions you could make on them. </p>
        <p>• The Status shows one of 3 statuses. The first is Pending, this will be thefirst step when your instructions are received. Once reviewed it will then either be Approved or Cancelled. If cancelled, we will then send you an email to tell you why. If approved, the payment will be made to your payee. Once this is done a proof of payment to your payee will be visible to you and the payment status will change to Paid. </p>
        </div>
    </div>
    </div>
  )
}

export default HowItWorks