import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function PreviewImage({ file }) {
    const [preview, setPreview] = useState()
    useEffect(() => {
        if (file instanceof File) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setPreview(reader.result);
            };
        } else if (typeof file === "string" && isValidUrl(file)) {
            setPreview(file);
        }
    }, [file]);

    function isValidUrl(url) {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    }
    const docs = [
        { uri: preview },
    ];
    return (
        <Box >
            <paper elevation={2}>
                {(preview?.endsWith('.pdf')) ?
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <DocViewer style={{ width: "27rem", height: "27rem" }} documents={docs} pluginRenderers={DocViewerRenderers} config={{
                                header: {
                                    disableHeader: true,
                                    disableFileName: true,
                                    retainURLParams: false
                                }
                            }} />
                        </Box>
                    </> :
                    <img style={{
                        width: "100%",
                        height: "20vh",
                        objectFit: "cover",
                        marginBottom: 20,
                    }} src={preview} alt="PDF FILE" />
                }
            </paper>
        </Box>
    )
}