import React from 'react'
import { makeStyles } from "@mui/styles"
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    bgColor: {
        backgroundColor: '#430454',
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:"column"
      },
      topLeftPolygon: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        borderTop:'300px solid #9A0FBF',
        borderRight: '300px solid transparent',
        [theme.breakpoints.down('sm')]: {
          borderTop: '100px solid #9A0FBF',
          borderRight: '100px solid transparent',
        },
      },
      bottomRightPolygon: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 0,
        height: 0,
        borderBottom:'300px solid #9A0FBF',
        borderLeft: '300px solid transparent',
        [theme.breakpoints.down('sm')]: {
          borderBottom: '100px solid #9A0FBF',
          borderLeft: '100px solid transparent', 
        },
      },
      iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop:"100px",
        flexDirection:"column"
      },
      icon: {
        width: '100px',
        height: '100px', 
      },
      kbm:{
        color: 'white', 
        fontFamily:'Inter',
        fontWeight:"bold"
      },
      title: {
        color: 'white', 
        fontSize: '80px',
        marginTop:"50px",
        fontFamily:'Poppins, sans-serif',
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
          // marginLeft: ".8rem",
        },
      },
      buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        marginTop:"80px",
        [theme.breakpoints.down("sm")]: {
          marginTop:'10px',
          flexDirection:'column',
        },
      },
      button: {
        color: 'white',
        borderRadius: '0px',
        cursor: 'pointer',
        fontSize: '20px',
        borderColor:"white",
        transition: 'background-color 0.3s ease-in-out',
        [theme.breakpoints.down("sm")]: {
          fontSize: '15px',
        },
      },
      buttonHover: {
        '&:hover': {
          backgroundColor: '#ecb9f9',
          color:'#430454'
        },
      },
}));

const LandingPage = () => {
    const classes = useStyles();
    const navigate = useNavigate()

    const handleLogin = ()=>{
        navigate('/login')
    }
    const handleRegister = ()=>{
        navigate('/signup')
    }
  return (
   <div className={classes.bgColor}>
   <div className={classes.topLeftPolygon}></div>
      <div className={classes.bottomRightPolygon}></div>
      <div className={classes.iconContainer}>
        <img src="/static/Group.png" alt="Icon" className={classes.icon} />
      <h3 className={classes.title}><span className={classes.kbm}>KBM &nbsp;</span>PAYMENTS</h3>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant='outlined' onClick={handleLogin} className={`${classes.button} ${classes.buttonHover}`}>Login</Button>
        <Button variant='outlined' onClick={handleRegister} className={`${classes.button} ${classes.buttonHover}`}>Register Your Interests</Button>
      </div>
   </div>
  )
}

export default LandingPage